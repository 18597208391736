import {useAuth, useInstance} from 'core/hooks';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
// redux
import {
  getOccupationRoles,
  getOccupationDetails,
  fetchOccupationRoles,
  fetchOccupationDetails,
  clearOccupationDetails,
  getSubscribedOccupation,
  fetchSubscribedOccupation,
  resetSubscribedOccupation,
} from 'redux/modules/occupation';
// Components
import {
  TabPage,
  CareerBanner,
  ErrorBoundary,
  AppBreadcrumb,
  BackgroundPaper,
  JobOpportunities,
  QualifyingPrograms,
  RequestErrorLoader,
} from 'mui-core';
import {Box, Container} from '@mui/material';
import CareerOverview from '../CareerDetails/components/Overview';
// Utils
import {queryStringParse} from 'core/utils';
import occupationData from 'data/occupation.json';
import {alpha, useTheme} from '@mui/material/styles';
import {exploreCareer, careerDetails} from 'data/exploreCareer.json';

const {loadingOccupationDetailsTxt} = occupationData;
const tabKeys = ['overview', 'programs', 'opportunities'];

const CareerDetails = () => {
  // hooks
  const theme = useTheme();
  const [token] = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {careerName = ''} = useParams();
  // states
  const [location, setLocation] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  // selectors
  const occupationRoles = useSelector(getOccupationRoles);
  const {request: subscribedOccupationReq} = useSelector(
    getSubscribedOccupation,
  );
  const occupationDetails = useSelector(getOccupationDetails);
  // utils
  const {
    location: {search},
  } = history;
  const {tabsTitles} = careerDetails;
  const params = queryStringParse(search);
  const {defaultLocation} = exploreCareer;
  const {
    request: occupDetailsRequest,
    data: {occupation_onnet = '', occupation_name = ''} = {},
  } = occupationDetails || {};
  const {
    data: {
      super_type_name = null,
      default_msa: {city = '', state_code = '', msa_code = ''} = {},
    } = {},
  } = useInstance();
  const {active: activeView} = params;

  const tabs = useMemo(() => {
    let availableTabs = [
      {
        key: tabKeys[0],
        title: tabsTitles[0],
        children: (
          <Box py={2}>
            <CareerOverview
              location={location}
              setLocation={setLocation}
              careerDetails={occupationDetails}
            />
          </Box>
        ),
      },
      {
        key: tabKeys[1],
        title: tabsTitles[1],
        children: (
          <Box py={2}>
            <QualifyingPrograms
              // fixedScrollHeight={570}
              occupationName={occupation_name}
              title='Explore Education and Training Programs'
              noResults='Sorry, we couldn’t find any programs for this career.'
            />
          </Box>
        ),
      },
      {
        key: tabKeys[2],
        title: tabsTitles[2],
        children: (
          <Box py={2}>
            <JobOpportunities
              scrollTop={280}
              // fixedScrollHeight={435}
              programTitle={occupation_name}
            />
          </Box>
        ),
      },
    ];
    return availableTabs.filter(ele => ele != null);
  }, [occupationDetails, occupation_name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let activeIdx = 0;
    if (activeView) {
      tabs.map((tab, idx) => {
        if (tab.key === activeView) {
          activeIdx = idx;
        }
      });
    }
    setActiveTab(activeIdx);
  }, [activeView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    token && !subscribedOccupationReq && dispatch(fetchSubscribedOccupation());
    return () => {
      dispatch(resetSubscribedOccupation());
    };
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    token &&
      occupation_onnet &&
      !occupationRoles.request &&
      dispatch(fetchOccupationRoles(occupation_onnet));
  }, [token, occupation_onnet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let defLoc = defaultLocation;
    if (city && state_code) {
      defLoc = `${city}, ${state_code}`;
    }
    const reqParams = {
      location: defLoc,
      query: careerName ?? '',
      msa_code: msa_code ?? '',
      // msa_code: '',
    };
    setLocation(defLoc);
    if (!super_type_name || (super_type_name && msa_code)) {
      careerName && dispatch(fetchOccupationDetails(reqParams));
    }
    return () => {
      dispatch(clearOccupationDetails());
    };
  }, [msa_code]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTabChange = index => {
    // setActiveTab(index);
    history.push(`/careers/${careerName}/?active=${tabs[index].key}`);
  };

  return (
    <BackgroundPaper
      background={{
        dark: theme.palette.darkGray.main,
        light: theme.palette.purplebg4perc.main,
      }}>
      <Container>
        <AppBreadcrumb
          dataList={[
            {
              name: 'Careers',
              path: '/careers',
            },
            {
              name: decodeURIComponent(careerName ? careerName : ''),
            },
          ]}
        />
        <ErrorBoundary nameOfComponent='career-details' typeOfUi='subPage'>
          <RequestErrorLoader
            fullScreen
            hideBackground
            title={loadingOccupationDetailsTxt}
            body={{
              ...occupationDetails,
              data: occupationDetails,
              request: occupDetailsRequest,
            }}
          />
          <BackgroundPaper
            px={4}
            py={2}
            borderRadius={4}
            background={{
              dark: '#222',
              light: theme.palette.purplebg8perc.main,
            }}>
            <CareerBanner occupationDetails={occupationDetails} />
          </BackgroundPaper>
          {occupation_name && (
            <Box sx={{mt: 4}}>
              <TabPage
                list={tabs}
                active={activeTab}
                onChange={(e, index) => onTabChange(index)}
              />
            </Box>
          )}
        </ErrorBoundary>
      </Container>
    </BackgroundPaper>
  );
};

export default CareerDetails;
